/* eslint-disable react/no-danger */
import {routes} from 'env/routes';
import {isProduction} from 'hooks/use-environment';
import Head from 'next/head';
import {useRouter} from 'next/router';
import React, {useMemo} from 'react';
import {AuthStore, CheckoutStore} from 'ui/components/withAppWrapper';
import {GoogleTagManager} from '../analytics/GoogleTagManager';
import {HubSpot} from '../analytics/HubSpot';
import {FloatingCtaContextProvider} from '../floating-cta/FloatingCtaContext';
import {DatoFooter} from '../footer/DatoFooter';
import {DatoImageRendererProvider} from '../image/ImageRenderer';
import {RunwayEastLink} from '../link/RunwayEastLink';
import {DatoResponsiveNavbar} from '../navbar/DatoResponsiveNavbar';
import {NavbarContextProvider} from '../navbar/NavbarContextProvider';

export interface RunwayEastLayoutProps {
  title: string;
  initialBackgroundColor?: string;
  children: React.ReactNode;
  checkoutStore: CheckoutStore;
  authStore: AuthStore;
  shouldShowOverflow?: boolean;
}

export const RunwayEastLayout: React.FC<RunwayEastLayoutProps> = ({
  title,
  children,
  authStore,
  checkoutStore,
  initialBackgroundColor,
  shouldShowOverflow,
}) => {
  const router = useRouter();

  const injectAnalyticsTags = useMemo(
    // Force these on for perf testing reasons
    () => true || (isProduction() && !router.isPreview),
    [router.isPreview],
  );

  return (
    <>
      {injectAnalyticsTags ? (
        <div>
          <HubSpot id={process.env.HUBSPOT_ID as string} />
          <GoogleTagManager id={process.env.GTM_ID as string} />
          <Head>
            <script
              dangerouslySetInnerHTML={{
                __html: `
              (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;
              h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};
              (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;
            })(window,document.documentElement,'async-hide','dataLayer',2000,{'OPT-NG2FHX2':true});
            `,
              }}
            />

            <style
              dangerouslySetInnerHTML={{
                __html: `.async-hide { opacity: 0 !important} `,
              }}
            />
          </Head>
        </div>
      ) : null}
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="google-site-verification" content="BUR9i0-To8jI4DkK59zLHx4BzVrXm4qqdO_cRn71jBQ" />
        <title>{title}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Head>
      <DatoImageRendererProvider>
        <FloatingCtaContextProvider>
          {/* TODO: extract items from MainMenu and place in DatoResponsiveNavbar.props.userMenuItems */}
          {/* <MainMenu /> */}
          <NavbarContextProvider
            initialBackgroundColor={initialBackgroundColor}
            shouldShowOverflow={shouldShowOverflow}
            NavbarComponent={
              <DatoResponsiveNavbar
                loggedIn={!!authStore?.accessToken}
                username={authStore?.customer?.FullName || undefined}
                currentRoute={router.asPath}
                numberOfBasketItems={checkoutStore?.basket?.length || 0}
                bookViewingHref="/book-tour"
              />
            }
            FooterComponent={
              <DatoFooter
                privacyPolicyHref={(routes as any).legal_privacy}
                termsAndConditionsHref={(routes as any).legal_terms}
                BottomRowLeftComponent={
                  <>
                    <a
                      className="lh-1 tdn"
                      href="https://twitter.com/runwayeast"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="icon-social-twitter fs-24" />
                    </a>
                    <a
                      className="lh-1 tdn"
                      href="https://www.instagram.com/runway_east/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="icon-social-instagram fs-24" />
                    </a>
                    <a
                      className="lh-1 tdn"
                      href="https://linkedin.com/company/runway-east"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="icon-social-linkedin fs-24" />
                    </a>
                  </>
                }
                BottomRowRightComponent={
                  <>
                    <RunwayEastLink
                      href={(routes as any).legal_terms}
                      className="text-gray-600 mr-12"
                    >
                      Terms and conditions
                    </RunwayEastLink>

                    <RunwayEastLink
                      href={(routes as any).legal_privacy}
                      className="text-gray-600 mr-12"
                    >
                      Privacy policy
                    </RunwayEastLink>

                    <RunwayEastLink
                      href={(routes as any).legal_cookies}
                      className="text-gray-600"
                    >
                      Cookies policy
                    </RunwayEastLink>
                  </>
                }
              />
            }
          >
            {children}
          </NavbarContextProvider>
        </FloatingCtaContextProvider>
      </DatoImageRendererProvider>
    </>
  );
};
